import { LanguageSettings, LanguagesArray } from '@yuno/api/interface';

/* eslint-disable @typescript-eslint/naming-convention */
export const inIframe = (): boolean => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

/**
 * Converts megabytes to bytes
 * @param megabytes
 */
export const megabytesToBytes = (megabytes: number): number => {
	return megabytes * 1_000_000;
};

export const radiansToDegrees = (radians: number): number => {
	return radians * (180 / Math.PI);
};

export const degreesToRadians = (degrees: number): number => {
	return degrees * (Math.PI / 180);
};

export const waitFor = (delay = 0): Promise<void> =>
	new Promise(resolve => setTimeout(resolve, delay));

// based on https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary?page=1&tab=trending#answer-12830454
export const round = (num: number | string, scale = 2) => {
	if (typeof num === 'string') {
		num = parseFloat(num);
	}

	if (!('' + num).includes('e')) {
		const useNum = parseFloat(num + 'e+' + scale);
		return +(Math.round(useNum) + 'e-' + scale);
	} else {
		const arr = ('' + num).split('e');
		let sig = '';
		if (+arr[1] + scale > 0) {
			sig = '+';
		}
		const useNum = parseFloat(+arr[0] + 'e' + sig + (+arr[1] + scale));
		return +(Math.round(useNum) + 'e-' + scale);
	}
};

export const createLanguageObject = (input: string): LanguageSettings => {
	const obj: LanguageSettings = {};

	// create a object of all languages
	for (const lang of LanguagesArray) {
		obj[lang] = input;
	}

	return obj;
};

export const absoluteUrl = (str: string): boolean => {
	const absolute = new RegExp('^(?:[a-z]+:)?//', 'i');
	return absolute.test(str);
};

export function isDate(value: unknown): boolean {
	return value instanceof Date || (typeof value === "string" && !isNaN(Date.parse(value)));
}

export function removeEmptyId<T>(obj: T): T {
	if (obj === null || obj === undefined) {
		return obj;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let newObj: any;
	if (typeof obj === 'object') {
		if (Array.isArray(obj)) {
			newObj = obj.map(item => removeEmptyId(item)).filter(Boolean);
		} else {
			newObj = {};
			let hasNonNullProps = false;
			for (const prop in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, prop)) {
					if (!(prop === '_id' && obj[prop] === '')) {
						const newPropValue = removeEmptyId(obj[prop]);
						if (newPropValue !== undefined && newPropValue !== null) {
							newObj[prop] = newPropValue;
							hasNonNullProps = true;
						}
					}
				}
			}
			newObj = hasNonNullProps ? newObj : undefined;
		}
	} else {
		newObj = obj;
	}

	return newObj;
}

// Generates a unique value
export function generateUniqueValue(array: string[], value: string): string {
	let increment = 1;
	let newValue = value;

	while (array.includes(newValue)) {
		newValue = `${value}_${increment}`;
		increment++;
	}

	return newValue;
}

export const isIOS = (): boolean => {
	return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const isAndroid = (): boolean => {
	return /android/i.test(navigator.userAgent);
};
