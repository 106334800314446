import { Params } from '@angular/router';

export const mobileScreenCutoff = 768;
export const mobileScreenCutoffTextfields = 768;
export const isMobile = (): boolean => {
	return window.innerWidth <= mobileScreenCutoff;
};
export const isMobileTextfields = (): boolean => {
	return window.innerWidth <= mobileScreenCutoffTextfields;
};

export const baseAtlasId = '66fba035a9aa6e4e4e42e97a';

export const headerAppId = 'X-YUNO-APP-ID';
export const headerVariantId = 'X-YUNO-VARIANT-ID';

export const routeParamsOrder = ['lang', 'variant', 'theme', 'subtheme', 'state'];
export const routeParamsState = ['lang', 'variant'];

export const generateRouteParams = (
	appName: string | undefined,
	params: Params,
	orderParams: string[]
): string[] => {
	const newRoute: string[] = [];
	if (appName) {
		newRoute.push(appName);
	}

	orderParams.forEach(param => {
		if (params[param]) {
			if (param === 'lang') {
				newRoute.push('lang', params[param]);
			} else if (param === 'variant') {
				newRoute.push('v', params[param]);
			} else {
				newRoute.push(params[param]);
			}
		}
	});

	return newRoute;
};
