export const MarkerSourceInclude = 'paMarker';
export const MarkersSource = 'paMarkers';
export const MarkersLayer = 'paMarkersInternalRenderer';

export const ParticipationSource = 'paParticipate';
export const ParticipationLayer = `${ParticipationSource}InternalRenderer`;
export const ParticipationIconsLayer = `${ParticipationSource}-icons`;
export const ParticipationIconsSelectionLayer = `${ParticipationSource}-icons-selection`;
export const ParticipationClusterLayer = `${ParticipationSource}paParticipate`;

export const MarkersCategoriesSource = 'paMarkersCategories';
export const MarkersCategoriesClusterSource = 'paMarkersCategoriesCluster';
export const MarkersCategoriesLayer = 'paMarkersCategoriesInternalRenderer';

export const PhotoCategoriesSource = 'paPhotoCategories';
export const PhotoCategoriesLayer = 'paPhotoCategoriesInternalRenderer';

export const PanoramaSource = 'paPanoramas';
export const PanoramaClusterSource = 'paPanoramasClustered';
export const PanoramaLayer = 'paPanoramaInternalRenderer';

export const PanoramaBackgroundLayer = 'panorama-background';
export const PanoramaColorsLayer = 'panorama-colors';
export const PanoramaClusterBackgroundLayer = 'panorama-cluster-background';
export const PanoramaClusterBackgroundIconLayer = 'panorama-cluster-background-icon';
export const PanoramaClusterCountLayer = 'panorama-cluster-count';

export const ShapesSource = 'paShapes';
export const DrawSource = 'paDraw';
